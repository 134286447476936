<template>
  <div v-click-outside="focusOut">
    <FloatLabel class="multi-dropdown" :class="`${selectDisabled ? 'float-label' : ''}`" :label="label" :optional="optional">
      <div>
        <div class="dropdown">
          <div class="overselect" @click="showDropdown"></div>
          <select 
            class="form-control placeholder select-custom"
            :class="selectDisabled ? 'disabled' : ''"
            :disabled="selectDisabled || false"
          >
            <option value="">{{ placeholder }}</option>
          </select>
        </div>
        <div class="multiselect" v-if="show">
          <ul>
            <li v-for="(option, index) in options" :key="index">
              <input type="checkbox" :id="index" :value="option" v-model="val">
              <label :for="index">{{ option }}</label>
            </li>
          </ul>
        </div>
      </div>
    </FloatLabel>
    <div class="fs-12 text-left mb-2 mt-n3 pr-2 err-text">{{ error ? helperText : '' }}</div>
  </div>
</template>
<script>
export default {
  components: { 
    FloatLabel: () => import('./FloatLabel.vue'),
  },
  name: 'CustomMultiSelect',
  model: {
    prop: 'selectValue',
    event: 'change'
  },
  data() {
    return {
      show: false,
    }
  },
  props: {
    selectValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
      default: () => ""
    },
    placeholder: {
      type: String,
      required: false,
      default: () => ""
    },
    name: {
      type: String,
      default: () => ""
    },
    error: Boolean,
    helperText: String,
    optional: Boolean,
    selectDisabled: Boolean,
  },
  methods: {
    showDropdown() {
      if (!this.selectDisabled) this.show = !this.show;
    },
    focusOut() {
      this.show = false;
    }
  },
  computed: {
    val: {
      get() {
        return this.selectValue;
      },
      set(val) {
        this.$emit('change', val);
      }
    }
  },
}
</script>
<style lang="scss">
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
select.form-control.invalid, input.form-control.invalid {
  border-color: red !important ;
}
select.form-control.disabled {
  border: none;
}
.float-label > span{
  z-index: 0;
  background-color: transparent;
  &::after {
    background: none;
  }
}
.err-text {
  color: red;
  font-style: italic;
  min-height: 16px;
}
.multi-dropdown {
  .dropdown { 
    position: relative; 
    cursor: pointer;
    .overselect {
      z-index: 999999;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    .placeholder {
      color: #989898 !important;
    }
  }
  label {
    all: unset !important;
    margin-left: 15px !important;
    font-size: 17px !important;
  }
  .multiselect {
    position: relative;
    ul {
      z-index: 99;
      cursor: pointer;
      border: 1px solid #ddd;
      background-color: #ffffff;
      border-radius: 0 0 3px 3px;
      left: 0px;
      padding: 14px 8px 8px 8px;
      position: absolute;
      top: -11px;
      width: 100%;
      list-style: none;
      max-height: 150px;
      overflow-x: hidden;
      margin-top: 11px;
      box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
    }
    input {
      accent-color: var(--primary-color);
    }
  }
}
</style>